import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ThemeContext from "./context/ThemeContext";
import AuthProvider from "./providers/AuthProvider";
import QueryProvider from "./providers/QueryProviders";
import LangProvider from "./providers/LangProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryProvider>
        <BrowserRouter basename="/">
          <ThemeContext>
            <AuthProvider>
              <LangProvider>
                <App />
              </LangProvider>
            </AuthProvider>
          </ThemeContext>
        </BrowserRouter>
      </QueryProvider>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
